import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Img from 'gatsby-image'

import IosIcon from '_images/svgs/ios.inline.svg'
import TapcartChallengeMobile from '_images/svgs/tapcart-challenge-mobile.inline.svg'
import { BlueText, Container, Heading, Image, ScrollArrow, Text } from '_atoms'
import { Quote, ProudCases } from '_molecules'
import { Layout } from '_templates'
import { SEO, ViewportObserver } from '_organisms'
import { AppContext } from '_context'
import variables from '_config/css-variables'
import TapcartLogo from '_images/svgs/tapcart-logo.inline.svg'
import TapcartChallenge from '_images/svgs/tapcart-challenge.inline.svg'
import TapcartIntegrating from '_images/svgs/tapcart-integrating.inline.svg'
import AndroidIcon from '_images/svgs/android.inline.svg'
import RocketIcon from '_images/svgs/rocket.inline.svg'
import SeparatorIcon from '_images/svgs/tapcart-separator.inline.svg'
import googleInstant from '_images/pages/portfolio/tapcart/google-instant.png'
import googlePay from '_images/pages/portfolio/tapcart/google-pay.png'

import styles from './styles.module.css'

const TapcartCaseStudy = ({ data }) => {
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const content = data.contentfulTapcartCaseStudy
  const { testimonial } = content

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor(variables['tapcart-primary'])
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  return (
    <Layout navbarColorOverride={variables['tapcart-primary']}>
      <SEO
        title={content.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          content.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={content.pageThumbnail.file.url}
      />

      <main className={styles.hero}>
        <Container className={styles.heroContainer}>
          <Row className={styles.heroRow}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <div className={styles.heroContent}>
                <Fade distance="10%" bottom>
                  <TapcartLogo className={styles.heroLogo} />
                </Fade>
                <div className={styles.heroText}>
                  <Fade delay={200} distance="10%" bottom>
                    <Text color="white" size="32" splitParagraphs className={styles.heroParagraph}>
                      {content.heroDescription.internal.content}
                    </Text>
                  </Fade>
                </div>
                <Fade delay={350} distance="20%" bottom>
                  <ScrollArrow className={styles.heroArrow} />
                </Fade>
              </div>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5} className={styles.heroPicture}>
              <Fade delay={350} distance="20%" bottom>
                <Img
                  fixed={content.heroImage.fixed}
                  alt={content.heroImage.description}
                  fadeIn={false}
                  imgStyle={{ objectFit: 'contain', width: '100%', height: '100%' }}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['tapcart-primary'])
            setNavbarLight(false)
          }}
        />
      </main>

      <div className={styles.blockWhite}>
        <section className={styles.challenge}>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={7} lg={7}>
                <div className={styles.challengeContent}>
                  <Fade distance="10%" bottom>
                    <Heading
                      type="h1"
                      bold
                      className={classNames(styles.heading, styles.challengeHeading)}
                    >
                      {content.challengeTitle}
                      <span className={styles.dot}>.</span>
                    </Heading>
                  </Fade>

                  <div className={styles.blockWhiteText}>
                    <Fade delay={200} distance="10%" bottom>
                      <Text color="dark" splitParagraphs className={styles.blockWhiteParagraph}>
                        {content.challengeDescription.internal.content}
                      </Text>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <TapcartChallenge className={styles.challengeIllustration} />
          <TapcartChallengeMobile className={styles.challengeIllustrationMobile} />
        </section>

        <section className={styles.integrating}>
          <Container className={styles.integratingContainer}>
            <Row className={styles.integratingRow}>
              <Col xs={12} sm={12} md={7} lg={7} className={styles.integratingCentralize}>
                <Fade distance="20%" bottom>
                  <TapcartIntegrating className={styles.integratingIllustration} />
                </Fade>
              </Col>
              <Col xs={12} sm={12} md={5} lg={5} className={styles.integratingContent}>
                <Fade delay={200} distance="10%" bottom>
                  <Heading type="h4" bold className={styles.heading}>
                    {content.integratingTitle}
                    <span className={styles.dot}>.</span>
                  </Heading>
                </Fade>

                <div className={styles.blockWhiteText}>
                  <Fade delay={200} distance="10%" bottom>
                    <Text color="dark" splitParagraphs className={styles.blockWhiteParagraph}>
                      {content.integratingDescription.internal.content}
                    </Text>
                  </Fade>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </div>

      <div className={styles.blockPrimary}>
        <section className={styles.design}>
          <Container className={styles.designContainer}>
            <Row className={styles.designRow}>
              <Col xs={12} sm={5} md={5} lg={5}>
                <div className={styles.designContent}>
                  <Fade distance="10%" bottom>
                    <Heading type="h4" bold className={styles.heading}>
                      {content.designTitle}
                      <span className={styles.dot}>.</span>
                    </Heading>
                  </Fade>

                  <div className={styles.blockPrimaryText}>
                    <Fade delay={200} distance="10%" bottom>
                      <Text color="dark" splitParagraphs className={styles.blockPrimaryParagraph}>
                        {content.designDescription.internal.content}
                      </Text>
                    </Fade>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={7} md={7} lg={7} className={styles.designScreenshots}>
                <div className={styles.designDevice}>
                  <IosIcon className={styles.designIcon} />
                  <Fade delay={350} distance="20%" bottom>
                    <Image {...content.designIos.fixed} alt={content.designIos.description} />
                  </Fade>
                </div>
                <Fade delay={400} distance="80%" bottom>
                  <SeparatorIcon className={styles.designSeparator} />
                </Fade>
                <div className={styles.designDevice}>
                  <AndroidIcon className={styles.designIcon} />
                  <Fade delay={450} distance="20%" bottom>
                    <Image
                      {...content.designAndroid.fixed}
                      alt={content.designAndroid.description}
                    />
                  </Fade>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles.architecture}>
          <Container className={styles.architectureContainer}>
            <Row className={styles.architectureRow}>
              <Col xs={12} sm={12} md={7} lg={7} className={styles.architectureImage}>
                <Fade delay={350} distance="20%" bottom>
                  <Image
                    {...content.architectureImage.fixed}
                    alt={content.architectureImage.description}
                  />
                </Fade>
              </Col>
              <Col xs={12} sm={12} md={5} lg={5} className={styles.architectureContent}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" bold className={styles.heading}>
                    {content.architectureTitle}
                    <span className={styles.dot}>.</span>
                  </Heading>
                </Fade>

                <div className={styles.blockPrimaryText}>
                  <Fade delay={200} distance="10%" bottom>
                    <Text color="dark" splitParagraphs className={styles.blockPrimaryParagraph}>
                      {content.architectureDescription.internal.content}
                    </Text>
                  </Fade>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['tapcart-primary'])
            setNavbarLight(false)
          }}
        />
      </div>

      <div className={styles.google}>
        <section className={styles.googleInstant}>
          <Container className={styles.googleContainer}>
            <Row className={styles.googleRow}>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div className={styles.googleContent}>
                  <Fade distance="10%" bottom>
                    <Heading type="h4" bold className={styles.heading}>
                      <Image
                        src={googleInstant}
                        alt="Google Instant"
                        className={styles.googleInstantLogo}
                      />
                    </Heading>
                  </Fade>

                  <div className={styles.blockPrimaryText}>
                    <Fade delay={200} distance="10%" bottom>
                      <Text color="dark" splitParagraphs className={styles.blockWhiteParagraph}>
                        {content.googleInstant.internal.content}
                      </Text>
                    </Fade>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={5} lg={5} className={styles.googleImageAlign}>
                <Fade delay={350} distance="10%" bottom>
                  <Image
                    {...content.googleInstantImage.fixed}
                    alt={content.googleInstantImage.description}
                    className={styles.googleImage}
                  />
                </Fade>
              </Col>
            </Row>
          </Container>

          <Fade delay={350} distance="10%" bottom>
            <Image
              {...content.googleInstantImageMobile.fixed}
              alt={content.googleInstantImageMobile.description}
              className={styles.googleImageMobile}
            />
          </Fade>
        </section>

        <section className={styles.googlePay}>
          <Container className={styles.googleContainer}>
            <Row className={styles.googleRow}>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div className={styles.googleContent}>
                  <Fade distance="10%" bottom>
                    <Heading type="h4" bold className={styles.heading}>
                      <Image src={googlePay} alt="Google Pay" className={styles.googlePayLogo} />
                    </Heading>
                  </Fade>

                  <div className={styles.blockPrimaryText}>
                    <Fade delay={200} distance="10%" bottom>
                      <Text color="dark" splitParagraphs className={styles.blockWhiteParagraph}>
                        {content.googlePay.internal.content}
                      </Text>
                    </Fade>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={5} lg={5} className={styles.googleImageAlign}>
                <Fade delay={350} distance="10%" bottom>
                  <Image
                    {...content.googlePayImage.fixed}
                    alt={content.googlePayImage.description}
                    className={styles.googleImage}
                  />
                </Fade>
              </Col>
            </Row>
          </Container>

          <Fade delay={350} distance="10%" bottom>
            <Image
              {...content.googlePayImageMobile.fixed}
              alt={content.googlePayImageMobile.description}
              className={styles.googleImageMobile}
            />
          </Fade>
        </section>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </div>

      <section className={styles.testimonial}>
        <div className={styles.testimonialContainer}>
          <Fade distance="10%" bottom>
            <Quote
              mobileComplete
              color="tapcartPrimary"
              text={testimonial.text.internal.content}
              user={testimonial.user}
              userPhoto={testimonial.userImage.fixed}
              userJob={testimonial.jobDescription}
            />
          </Fade>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.results}>
        <div className={styles.resultsBlock}>
          <Container className={styles.resultsContainer}>
            <div className={styles.resultsItem}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h4"
                  bold
                  color="blueAccent"
                  className={classNames(styles.resultsHeading, styles.resultsHighlight)}
                >
                  {content.workingTogether}{' '}
                  <span className={styles.resultsYears}>
                    <FormattedMessage id="casesCommon.years" />
                  </span>
                </Heading>
              </Fade>
              <Fade delay={200} distance="10%" bottom>
                <Text color="white" size="18">
                  <FormattedMessage id="casesCommon.workingTogether" />
                </Text>
              </Fade>
            </div>
            <div className={styles.resultsItem}>
              <Fade delay={350} distance="20%" bottom>
                <AndroidIcon className={styles.resultsAndroid} />
              </Fade>
              <div className={styles.resultsText}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" bold color="blueAccent" className={styles.resultsHeading}>
                    {content.appLaunches}
                  </Heading>
                </Fade>
                <Fade delay={200} distance="10%" bottom>
                  <Text color="white" size="18">
                    <FormattedMessage id="tapcart.appLaunches" />
                  </Text>
                </Fade>
              </div>
            </div>
            <div className={styles.resultsItem}>
              <Fade delay={350} distance="20%" bottom>
                <RocketIcon className={styles.resultsRocket} />
              </Fade>
              <div className={styles.resultsText}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" bold color="blueAccent" className={styles.resultsHeading}>
                    {content.highlightApp}
                  </Heading>
                </Fade>
                <Fade delay={200} distance="10%" bottom>
                  <Text color="white" size="18">
                    {content.highlightAppInfo}
                  </Text>
                </Fade>
              </div>
            </div>
            <div className={styles.resultsInfo}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="white" className={styles.resultsHeading}>
                  <FormattedMessage id="casesCommon.results" />
                  <BlueText light squared>
                    .
                  </BlueText>
                </Heading>
              </Fade>
              <Fade delay={200} distance="10%" bottom>
                <Text color="white" className={styles.resultsInfoText}>
                  {content.info}
                </Text>
              </Fade>
            </div>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['tapcart-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <div className={styles.portfolio}>
        <ProudCases currentProject="Tapcart" caseStudies={content.proudCases} extendedCTA />

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query TapcartCaseStudyContent($locale: String) {
    contentfulTapcartCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      heroImage {
        description
        fixed {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      integratingTitle
      integratingDescription {
        internal {
          content
        }
      }
      designTitle
      designDescription {
        internal {
          content
        }
      }
      designIos {
        description
        fixed {
          src
          srcSet
        }
      }
      designAndroid {
        description
        fixed {
          src
          srcSet
        }
      }
      architectureTitle
      architectureDescription {
        internal {
          content
        }
      }
      architectureImage {
        description
        fixed {
          src
          srcSet
        }
      }
      googleInstant {
        internal {
          content
        }
      }
      googleInstantImage {
        description
        fixed {
          src
          srcSet
        }
      }
      googleInstantImageMobile {
        description
        fixed {
          src
          srcSet
        }
      }
      googlePay {
        internal {
          content
        }
      }
      googlePayImage {
        description
        fixed {
          src
          srcSet
        }
      }
      googlePayImageMobile {
        description
        fixed {
          src
          srcSet
        }
      }
      testimonial {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      workingTogether
      appLaunches
      highlightApp
      highlightAppInfo
      info
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            src
            srcSet
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

TapcartCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default TapcartCaseStudy
